import React from 'react'
import { testResultsHeaders } from '../../../constants/headers'
import { useTestResultsContext } from '../../../contexts/testResultsContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import GuidelineRow from './guidelineRow'
import TableHeader from './tableHeader'

const TableHeaderRows = () => {
  const blanks = Array.from({ length: 7 }, (_, i) => i)
  const { activeGuidelines, units, chemicals } = useTestResultsContext()
  const resultBlanks = Array.from({ length: chemicals.length + 1 }, (_, i) => i)
  return (
    <thead className={tableStyles.tableHeaderRow}>
      <tr className={tableStyles.tableHeaderRow}>
        {blanks.map((index) => (
          <TableHeader style={tableStyles.bottomTh} key={`${index}-blank-chemical`} content={''} />
        ))}
        <TableHeader content={'CHEMICAL'} style={tableStyles.bottomTh}/>
        {chemicals.map((chemical, index) => (
          <TableHeader key={`${index}-chemical`} style={tableStyles.bottomTh} content={chemical.name} sideways={true} />
        ))}
      </tr>
      <tr className={tableStyles.tableHeaderRow}>
        {blanks.map((index) => (
          <TableHeader key={`${index}-blank-unit`} content={''} />
        ))}
        <TableHeader content={'UNIT'} style={tableStyles.bottomTh} />
        {units.map((unit, index) => (
          <TableHeader key={`${index}-unit`} content={unit.unit} />
        ))}
      </tr>
      {activeGuidelines.map((guideline) => (
        <GuidelineRow key={guideline.id} guideline={guideline} />
      ))}
      <tr className={tableStyles.tableHeaderRow}>
        {testResultsHeaders.map((testResultHeader, index) => (
          <TableHeader style={tableStyles.bottomTh} key={`${index}-headers`} content={testResultHeader.title} />
        ))}
        {resultBlanks.map((index) => (
          <TableHeader key={`${index}-blank-results`} content={''} />
        ))}
      </tr>
    </thead>
  )
}

export default TableHeaderRows
