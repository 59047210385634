import React from 'react'
import { useTestResultsApi, useTestResultsContext } from '../../../contexts/testResultsContext'
import LoadingSpinner from '../../shared/loadingSpinner'
import StandardButton from '../../shared/standardButton'
import GuidelineDisplay from './guidelineDisplay'
import GuidelineModal from './guidelineModal'
import TestResultsTable from './testResultsTable'

const LayoutTable = () => {
  const { loading, chemicalUnits, modal } = useTestResultsContext()
  const { toggleField } = useTestResultsApi()
  return (
    <>
      <div className={`w-full px-2 py-4 bg-gray-200 flex justify-center`}>
        <h2 className="text-2xl font-bold">Test Results</h2>
      </div>
      <div className="flex justify-between items-center">
        <div className="text-left">
          <StandardButton buttonText={'Add Guideline'} onClickHandler={() => toggleField('modal.guidelineModal')} />
        </div>
        <div className="flex space-x-2">
          <div className="m-4 bg-red-200 flex items-center justify-center p-2"><strong> Does Not Meet Requirements </strong></div>
          <div className="m-4 bg-yellow-200 flex items-center justify-center p-2"><strong> Background </strong></div>
        </div>
      </div>
      {modal.guidelineModal && <GuidelineModal />}
      <GuidelineDisplay />
      {!loading && !chemicalUnits.length == 0 && <TestResultsTable />}
      {loading && <LoadingSpinner />}

    </>
  )
}

export default LayoutTable
