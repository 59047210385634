import React from 'react'
import { useTestResultsContext } from '../../../contexts/testResultsContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import TableHeaderRows from './tableHeaderRows'
import TestResultModal from './testResultModal'
import TestResultRow from './testResultRow'

const TestResultsTable = () => {
  const { testResultRows, modal } = useTestResultsContext()
  return (
    <div className="m-4 w-full">
      {modal.testResultModal && <TestResultModal />}
      <table className={tableStyles.table}>
        <TableHeaderRows length ={length} />
        <tbody>          
          {testResultRows.map((row, index) => (
            <TestResultRow key={index} row={row} rowIndex={index} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TestResultsTable
