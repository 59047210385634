import { getDocument, getTemplate, updateDocument } from "../api/documentLogApi"
import { directedFlatten, mapArrayToObject, optionize, pipe, pluck } from "./utils"
import { omit } from "lodash"
import TableLayout from "../components/documentBuilder/section/tableLayout"
import DefaultLayout from "../components/documentBuilder/section/defaultLayout"

const orderedContentIds = contents => pluck(contents.sort((a, b) => a.position - b.position), 'id')
const newSectionLayout = ({ id, name, type, columns, borders, default_contents }) =>
  ({ id, name, type, columns, borders, contentIds: orderedContentIds(default_contents) })

const newSection = ({ id, title, position, section_layouts, currentSectionLayout }) => ({
  id,
  title,
  position,
  currentSectionLayout: currentSectionLayout || section_layouts[0]?.id,
  sectionLayouts: section_layouts.map(sectionLayout => newSectionLayout(sectionLayout))
})

const newTemplate = ({ id, name, slug }) => ({ id, name, slug })

export const newDocument = ({ id, document_name, docx_file_path, pdf_file_path }) => {
  return { id, documentName: document_name, docxFilePath: docx_file_path, pdfFilePath: pdf_file_path }
}

const newSections = (sections, sectionLayouts) => {
  const selectedSectionLayouts = pluck(sectionLayouts, 'id')
  return sections.map(section => newSection({
    ...section,
    currentSectionLayout: section.section_layouts.find(layout => selectedSectionLayouts.includes(layout.id))?.id
  }))
}

const newDataSourceDefinition = ({ id, property_name: propertyName, property_type: propertyType }) =>
  ({ id, propertyName, propertyType })

const newDataSource = ({ id, name, definitions }) =>
  ({ id, name, definitions: definitions.map(definition => newDataSourceDefinition(definition)) })

const newDataSources = (dataSources) => dataSources.map(dataSource => newDataSource(dataSource))

const newDocumentDefinitions = documentDefinitions => {
  const options = {
    key: 'data_source_definition_id',
    removeKey: true,
    propertyMappings: { edited_value: 'editedValue', data_source_value: 'dataSourceValue' }
  }
  return mapArrayToObject(options)(documentDefinitions)
}

const mergeDocumentContents = documentContents => defaultContents => {
  return documentContents.reduce((content, docContent) => {
    const contentId = docContent.default_content_id
    const documentContent = { documentContent: docContent?.body, ...omit(docContent, 'body', 'default_content_id') }

    return { ...content, [contentId]: Object.assign({}, content[contentId], documentContent) }
  }, defaultContents)
}

const initializeDocumentContents = (templateSections, documentContents) => {
  const propertyMapObject = { body: 'defaultContent', allow_other: 'allowOther' }
  return pipe(
    directedFlatten('section_layouts'),
    directedFlatten('default_contents'),
    mapArrayToObject({ removeKey: true, propertyMappings: propertyMapObject }),
    mergeDocumentContents(documentContents)
  )(templateSections)
}

export const initializeState = async ({ projectId, templateSlug, documentId, updateState }) => {
  const document = documentId ? await getDocument(projectId, templateSlug, documentId) : { contents: [] }
  const template = await getTemplate(projectId, templateSlug, documentId)
  const sections = newSections(template.sections, documentId ? document.section_layouts : [])

  updateState('template', newTemplate(template))
  updateState('document', newDocument(document))
  updateState('sections', sections)
  updateState('contents', initializeDocumentContents(template.sections, document.contents))
  updateState('dataSources', newDataSources(template.data_sources))
  updateState('documentDefinitions', newDocumentDefinitions(document.definitions))
}

export const updateContentState = (stateContents, field, value, contentId) => {
  return { ...stateContents, [contentId]: { ...stateContents[contentId], [field]: value } }
}

export const updateContentsState = (stateContents, newContents) => {
  const targetIds = pluck(newContents, "default_content_id").map(id => id.toString())

  return Object.entries(stateContents).reduce((acc, [key, value]) => {
    if (targetIds.includes(key)) {
      const updatedState = newContents.find(newCont => newCont.default_content_id.toString() === key)
      return { ...acc, [key]: { ...value, id: updatedState.id, documentContent: updatedState.body } }
    } else {
      return { ...acc, [key]: value }
    }
  }, {})
}

export const updateSectionState = (stateSections, field, value, sectionId) => {
  return stateSections.map(section => section.id !== sectionId ? section : { ...section, [field]: value })
}

const mergeListItems = (node) => {
  const childNodes = node.childNodes

  // If the node is a list item with only a single child of type ul and has a previous sibling
  if (node.nodeName === 'LI' && childNodes.length === 1 && ['UL', 'OL'].includes(childNodes[0].nodeName) && node.previousSibling) {
    node.previousSibling.appendChild(childNodes[0])
    return true
  }

  for (let i = childNodes.length - 1; i >= 0; i--) {
    const child = childNodes[i]
    mergeListItems(child) && node.removeChild(child)
  }

  return false
}

const formatContentBody = (contentId, body, id) => {
  const domParser = new DOMParser()
  const xmlParser = new XMLSerializer()
  const contentStrings = []

  const shadowDom = domParser.parseFromString(body, 'text/html')

  mergeListItems(shadowDom.body)

  for (const child of shadowDom.body.childNodes.values()) {
    contentStrings.push(xmlParser.serializeToString(child).replace('xmlns="http://www.w3.org/1999/xhtml"', ''))
  }

  return { default_content_id: contentId, body: contentStrings.join(''), id: id }
}

export const saveDocument = async ({ contents, sections, projectId, templateSlug, documentId }) => {
  const contentParams = Object.entries(contents).reduce((acc, [contentId, content]) => {
    return content.type === 'Static' ? acc : [...acc, formatContentBody(contentId, content.documentContent, content.id)]
  }, [])

  const body = {
    section_layout_ids: pluck(sections, 'currentSectionLayout'),
    contents_attributes: contentParams
  }

  return updateDocument(projectId, templateSlug, documentId, body)
}

export const componentSwitch = currentLayoutType => {
  switch (currentLayoutType) {
    case 'TableLayout':
      return TableLayout
    default:
      return DefaultLayout
  }
}

export const contentDropdownOptions = content => {
  return [
    ...optionize('value')(content.options),
    content?.allowOther && { value: 'other', label: 'Other' }
  ].filter(i => i)
}

export const contentDropdownDefaultOption = (options, content) => {
  return options.find(option => option.value === content.documentContent) ||
    content?.allowOther && content.documentContent && { label: "Other", value: "other" } || options[0]
}
